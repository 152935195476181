import * as React from "react";
import { IconProps } from "../types";

const PhotoOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M4 14.7501L9.836 8.91412C10.617 8.13312 11.883 8.13312 12.664 8.91412L16.25 12.5001"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.75 14.75L14.25 14.75C15.3546 14.75 16.25 13.8546 16.25 12.75V5.25C16.25 4.14543 15.3546 3.25 14.25 3.25L3.75 3.25C2.64543 3.25 1.75 4.14543 1.75 5.25L1.75 12.75C1.75 13.8546 2.64543 14.75 3.75 14.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.75 8.5C6.44036 8.5 7 7.94036 7 7.25C7 6.55964 6.44036 6 5.75 6C5.05964 6 4.5 6.55964 4.5 7.25C4.5 7.94036 5.05964 8.5 5.75 8.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default PhotoOutlineIcon;

import * as React from "react";
import { IconProps } from "../types";

const VideoOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.25 7.99993L16.509 5.65793C16.842 5.47493 17.25 5.71593 17.25 6.09593V11.9049C17.25 12.2849 16.842 12.5259 16.509 12.3429L12.25 10.0009"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.25 3.75H3.75C2.64543 3.75 1.75 4.64543 1.75 5.75V12.25C1.75 13.3546 2.64543 14.25 3.75 14.25H10.25C11.3546 14.25 12.25 13.3546 12.25 12.25V5.75C12.25 4.64543 11.3546 3.75 10.25 3.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.75 7.5C5.16421 7.5 5.5 7.16421 5.5 6.75C5.5 6.33579 5.16421 6 4.75 6C4.33579 6 4 6.33579 4 6.75C4 7.16421 4.33579 7.5 4.75 7.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default VideoOutlineIcon;

import { CoverImageFragment, MediaFragment } from "@src/fragments.generated";
import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "@src/ui/button";
import Text from "@src/ui/text";
import gql from "graphql-tag";
import { useToast } from "@src/hooks/useToast";
import { useAddMediaModalCreateMediaMutation } from "@components/modals/addMedia/AddMediaModal.generated";
import Resources from "./Resources";
import PasteLink from "./PasteLink";
import StockPhotos from "./StockPhotos";
import StockVideos from "./StockVideos";
import GIFs from "./GIFs";
import { emptyTranslationSet } from "@utils/prompts";
import {
  PexelsPhoto_StockPhotosFragment,
  UnsplashImage_StockPhotosFragment,
} from "./StockPhotos.generated";
import { PexelsVideoQuality } from "@src/types.generated";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import LinkIcon from "@src/ui/icons/18px/link";
import FolderOpenOutlineIcon from "@src/ui/icons/18px/folder-open-outline";
import VideoOutlineIcon from "@src/ui/icons/18px/video-outline";
import GifIcon from "@src/ui/icons/18px/gif";
import PlusIcon from "@src/ui/icons/18px/plus";
import {
  useFileUpload,
  MediaFragment as FileUploadMediaFragment,
} from "@hooks/useFileUpload";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { ACCEPTED_FILE_TYPES, LinkType } from "@src/utils/files";
import { Dialog, DialogTrigger, DialogContent } from "@src/ui/dialog";
import EmojiOutlineIcon from "@src/ui/icons/18px/emoji-outline";
import Emojis from "@src/components/modals/addMedia/Emojis";

type Props = {
  title: string;
  onMediaChange: (media: MediaFragment) => void;
  filterResources?: LinkType[];
  trigger: ReactNode;
  initialCoverImage?: CoverImageFragment;
  onCoverImageChange?: (coverImage: Omit<CoverImageFragment, "id">) => void;
};

export enum Tabs {
  RESOURCES,
  PASTE_LINK,
  IMAGES,
  VIDEOS,
  GIFS,
  EMOJIS,
}

const DEFAULT_TABS = [
  Tabs.GIFS,
  Tabs.IMAGES,
  Tabs.VIDEOS,
  Tabs.PASTE_LINK,
  Tabs.RESOURCES,
];

const TAB_BUTTON_COPY = {
  [Tabs.RESOURCES]: "My Resources",
  [Tabs.PASTE_LINK]: "Paste Link",
  [Tabs.IMAGES]: "Stock Photos",
  [Tabs.VIDEOS]: "Stock Videos",
  [Tabs.GIFS]: "GIFs",
  [Tabs.EMOJIS]: "Emojis",
};

const TAB_BUTTON_ICON = {
  [Tabs.RESOURCES]: FolderOpenOutlineIcon,
  [Tabs.PASTE_LINK]: LinkIcon,
  [Tabs.IMAGES]: PhotoOutlineIcon,
  [Tabs.VIDEOS]: VideoOutlineIcon,
  [Tabs.GIFS]: GifIcon,
  [Tabs.EMOJIS]: EmojiOutlineIcon,
};

const AddMediaModal: FC<Props> = ({
  title,
  onMediaChange,
  filterResources,
  initialCoverImage,
  onCoverImageChange,
  trigger,
}) => {
  const tabs = useMemo(() => {
    const t = [];
    if (!filterResources) {
      t.push(Tabs.RESOURCES);
      t.push(Tabs.PASTE_LINK);
    }
    if (!filterResources || filterResources.includes(LinkType.IMAGE)) {
      t.push(Tabs.IMAGES);
    }
    if (!filterResources || filterResources.includes(LinkType.VIDEO)) {
      t.push(Tabs.VIDEOS);
    }
    if (!filterResources || filterResources.includes(LinkType.GIF)) {
      t.push(Tabs.GIFS);
    }
    if (filterResources && filterResources.includes(LinkType.EMOJI)) {
      t.push(Tabs.EMOJIS);
    }
    return t;
  }, [filterResources]);
  const [selectedTab, setSelectedTab] = useState(
    DEFAULT_TABS.find((tab) => tabs.includes(tab)) || Tabs.RESOURCES,
  );
  const { addErrorToast } = useToast();
  const [open, setOpen] = useState(false);
  const onMediaChangeAndClose = useCallback(
    (media: MediaFragment) => {
      setOpen(false);
      onMediaChange(media);
    },
    [setOpen, onMediaChange],
  );
  const [createMedia] = useAddMediaModalCreateMediaMutation({
    onCompleted(data) {
      if (data.createMedia.success && data.createMedia.media) {
        onMediaChangeAndClose(data.createMedia.media);
      } else {
        addErrorToast({
          ...data.createMedia.error,
          callsite: "add_media_modal",
        });
      }
    },
    onError: (error) => {
      addErrorToast({
        ...error,
        callsite: "add_media_modal",
      });
    },
  });
  const onAddLink = useCallback(
    (link: string) => {
      createMedia({
        variables: {
          input: {
            mediaUrls: emptyTranslationSet({
              en: link,
              translationsDisabled: true,
            }),
            name: emptyTranslationSet({
              en: link,
            }),
          },
        },
      });
    },
    [createMedia],
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, { loading: uploadLoading }] = useFileUpload(
    (m: FileUploadMediaFragment) => {
      createMedia({
        variables: {
          input: {
            mediaUrls: emptyTranslationSet({
              en: m.url,
              translationsDisabled: true,
            }),
          },
        },
      });
    },
  );
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger onClick={(e) => e.stopPropagation()} asChild>
        {trigger}
      </DialogTrigger>
      <DialogContent className="z-[1000] w-[800px] max-w-[800px] shadow-xl">
        <div className="flex h-[85vh] w-[725px] items-start gap-10">
          <div className="flex h-full w-[212px] shrink-0 flex-col items-start self-stretch overflow-y-auto">
            <Text type="P1" fontWeight="SemiBold" className="mb-2">
              {title}
            </Text>
            <>
              {tabs.map((tab) => (
                <MenuButton
                  key={`add-media-tab-${tab}`}
                  tab={tab}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              ))}
            </>
            <input
              ref={fileInputRef}
              type="file"
              id="fileInput"
              className="hidden"
              multiple={false}
              accept={ACCEPTED_FILE_TYPES.toString()}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  uploadFile(e.target.files[0]);
                }
              }}
            />
            <div className="flex flex-1 flex-col items-end justify-end gap-2 self-stretch">
              <Button
                className="self-stretch"
                onClick={() => fileInputRef.current?.click()}
                disabled={uploadLoading}
              >
                {uploadLoading ? (
                  <Spinner size={18} color="white" />
                ) : (
                  <PlusIcon />
                )}
                {uploadLoading ? "Uploading" : "Upload"}
              </Button>
              <Button
                variant="outline"
                className="self-stretch"
                onClick={() => setOpen(false)}
                disabled={uploadLoading}
              >
                Done
              </Button>
            </div>
          </div>
          <div className="flex h-full w-[473px] flex-col items-start gap-1.5">
            {selectedTab === Tabs.RESOURCES && (
              <Resources
                onResourceClick={onMediaChangeAndClose}
                filterResources={filterResources}
              />
            )}
            {selectedTab === Tabs.PASTE_LINK && (
              <PasteLink onAddLink={onAddLink} />
            )}
            {selectedTab === Tabs.IMAGES && (
              <StockPhotos
                onPexelsPhotoSelect={(
                  photo: PexelsPhoto_StockPhotosFragment,
                ) => {
                  createMedia({
                    variables: {
                      input: {
                        mediaUrls: emptyTranslationSet({
                          en: photo.src.original,
                          translationsDisabled: true,
                        }),
                        name: emptyTranslationSet({
                          en: photo.alt,
                        }),
                      },
                    },
                  });
                }}
                onUnsplashImageSelect={(
                  image: UnsplashImage_StockPhotosFragment,
                ) => {
                  createMedia({
                    variables: {
                      input: {
                        mediaUrls: emptyTranslationSet({
                          en: image.urls.full,
                          translationsDisabled: true,
                        }),
                        name: emptyTranslationSet({
                          en: image.altDescription || image.urls.full,
                        }),
                      },
                    },
                  });
                }}
              />
            )}
            {selectedTab === Tabs.VIDEOS && (
              <StockVideos
                onPexelsVideoSelect={(video) => {
                  let videoFile = video.videoFiles.find(
                    (f) => f.quality === PexelsVideoQuality.Hd,
                  );
                  if (!videoFile) {
                    videoFile = video.videoFiles[0];
                  }
                  createMedia({
                    variables: {
                      input: {
                        mediaUrls: emptyTranslationSet({
                          en: videoFile.link,
                          translationsDisabled: true,
                        }),
                        name: emptyTranslationSet({
                          en: videoFile.link,
                        }),
                      },
                    },
                  });
                }}
              />
            )}
            {selectedTab === Tabs.GIFS && (
              <GIFs
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onGIFSelect={(gif: any) => {
                  createMedia({
                    variables: {
                      input: {
                        mediaUrls: emptyTranslationSet({
                          en: gif.images.downsized_large.url.split("?")[0],
                          translationsDisabled: true,
                        }),
                        name: emptyTranslationSet({
                          en: gif.title,
                        }),
                      },
                    },
                  });
                }}
              />
            )}
            {selectedTab === Tabs.EMOJIS && (
              <Emojis
                coverImage={initialCoverImage}
                onCoverImageSelected={onCoverImageChange}
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const MenuButton: FC<{
  tab: Tabs;
  selectedTab: Tabs;
  setSelectedTab: (tab: Tabs) => void;
}> = ({ tab, selectedTab, setSelectedTab }) => {
  const Icon = TAB_BUTTON_ICON[tab];
  return (
    <Button
      variant={selectedTab === tab ? "secondary" : "ghost"}
      onClick={() => setSelectedTab(tab)}
      className="w-full justify-start"
    >
      <Icon />
      {TAB_BUTTON_COPY[tab]}
    </Button>
  );
};

export default AddMediaModal;

gql`
  query AddMediaModalTrainingResources($search: String!) {
    TrainingResources: AdminTrainingResources(search: $search) {
      id
      media {
        ...Media
      }
    }
  }
  mutation AddMediaModalCreateMedia($input: MediaInput!) {
    createMedia(input: $input) {
      success
      error {
        code
      }
      media {
        ...Media
      }
    }
  }
`;

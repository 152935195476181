import React, { FC, useRef, useEffect, IframeHTMLAttributes } from "react";

interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {
  onClick?: () => void;
  title: string;
}

const ClickableIframe: FC<Props> = (props) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const { onClick } = props;
  useEffect(() => {
    const onBlur = () => {
      if (
        onClick &&
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === "iframe" &&
        ref.current &&
        ref.current === document.activeElement
      ) {
        onClick();
      }
    };

    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, [ref, onClick]);

  return <iframe {...props} ref={ref} title={props.title} />;
};

export default ClickableIframe;

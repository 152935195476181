export function compressUnsplashImageURL(url: string, width: number): string {
  const urlObj = new URL(url);
  const paramsObj = new URLSearchParams(urlObj.search);
  const newUrl = urlObj.origin + urlObj.pathname;
  return `${newUrl}?ixid=${paramsObj.get("ixid")}&auto=format&dpr=1&q=80&w=${width}`;
}

export function compressPexelsImageURL(url: string, width: number): string {
  return `${url}?auto=compress&cs=tinysrgb&dpr=2&fit=crop&w=${width}`;
}

import { FC } from "react";
import { Skeleton } from "@src/ui/skeleton";

const HEIGHTS = [
  110, 110, 110, 170, 110, 110, 110, 170, 110, 110, 110, 110, 110, 110, 170,
  110, 170, 170, 170, 110, 110, 110,
];

const Loading: FC = () => (
  <div className="columns-4 gap-2 overflow-hidden">
    {HEIGHTS.map((height, i) => (
      <Skeleton
        style={{ height }}
        key={`add-media-loading-${i}-${height}`}
        className={`mb-2 w-[110px] break-inside-avoid-column`}
      />
    ))}
  </div>
);

export default Loading;

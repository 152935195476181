import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc, TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ContentQrCodeFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc, MyTranslationSetFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddMediaModalTrainingResourcesQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type AddMediaModalTrainingResourcesQuery = { __typename?: 'Query', TrainingResources: Array<{ __typename?: 'TrainingResource', id: string, media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null } }> };

export type AddMediaModalCreateMediaMutationVariables = Types.Exact<{
  input: Types.MediaInput;
}>;


export type AddMediaModalCreateMediaMutation = { __typename?: 'Mutation', createMedia: { __typename?: 'MediaMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null } | null } };


export const AddMediaModalTrainingResourcesDocument = gql`
    query AddMediaModalTrainingResources($search: String!) {
  TrainingResources: AdminTrainingResources(search: $search) {
    id
    media {
      ...Media
    }
  }
}
    ${MediaFragmentDoc}`;

/**
 * __useAddMediaModalTrainingResourcesQuery__
 *
 * To run a query within a React component, call `useAddMediaModalTrainingResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMediaModalTrainingResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMediaModalTrainingResourcesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAddMediaModalTrainingResourcesQuery(baseOptions: Apollo.QueryHookOptions<AddMediaModalTrainingResourcesQuery, AddMediaModalTrainingResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMediaModalTrainingResourcesQuery, AddMediaModalTrainingResourcesQueryVariables>(AddMediaModalTrainingResourcesDocument, options);
      }
export function useAddMediaModalTrainingResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMediaModalTrainingResourcesQuery, AddMediaModalTrainingResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMediaModalTrainingResourcesQuery, AddMediaModalTrainingResourcesQueryVariables>(AddMediaModalTrainingResourcesDocument, options);
        }
export type AddMediaModalTrainingResourcesQueryHookResult = ReturnType<typeof useAddMediaModalTrainingResourcesQuery>;
export type AddMediaModalTrainingResourcesLazyQueryHookResult = ReturnType<typeof useAddMediaModalTrainingResourcesLazyQuery>;
export type AddMediaModalTrainingResourcesQueryResult = Apollo.QueryResult<AddMediaModalTrainingResourcesQuery, AddMediaModalTrainingResourcesQueryVariables>;
export const AddMediaModalCreateMediaDocument = gql`
    mutation AddMediaModalCreateMedia($input: MediaInput!) {
  createMedia(input: $input) {
    success
    error {
      code
    }
    media {
      ...Media
    }
  }
}
    ${MediaFragmentDoc}`;
export type AddMediaModalCreateMediaMutationFn = Apollo.MutationFunction<AddMediaModalCreateMediaMutation, AddMediaModalCreateMediaMutationVariables>;

/**
 * __useAddMediaModalCreateMediaMutation__
 *
 * To run a mutation, you first call `useAddMediaModalCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaModalCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaModalCreateMediaMutation, { data, loading, error }] = useAddMediaModalCreateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMediaModalCreateMediaMutation(baseOptions?: Apollo.MutationHookOptions<AddMediaModalCreateMediaMutation, AddMediaModalCreateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMediaModalCreateMediaMutation, AddMediaModalCreateMediaMutationVariables>(AddMediaModalCreateMediaDocument, options);
      }
export type AddMediaModalCreateMediaMutationHookResult = ReturnType<typeof useAddMediaModalCreateMediaMutation>;
export type AddMediaModalCreateMediaMutationResult = Apollo.MutationResult<AddMediaModalCreateMediaMutation>;
export type AddMediaModalCreateMediaMutationOptions = Apollo.BaseMutationOptions<AddMediaModalCreateMediaMutation, AddMediaModalCreateMediaMutationVariables>;
import * as React from "react";
import { IconProps } from "../types";

const CheckmarkCurvedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M1.75 5.9999C2.797 7.0479 3.553 8.1529 4.211 9.5789C5.735 6.5029 7.87 4.1819 10.25 2.4209"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default CheckmarkCurvedIcon;

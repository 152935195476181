import Text from "@src/ui/text";
import { FC } from "react";

type Props = {
  name: string;
  url?: string;
};

const AttributionTag: FC<Props> = ({ name, url }) => (
  <Text
    type="P4"
    className="absolute bottom-[5px] left-[5px] max-w-[90%] truncate rounded-md bg-opacity-[50%] p-1 text-xs text-background backdrop-blur"
  >
    by{" "}
    {url ? (
      <a
        href={url || ""}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        className="hover:underline"
      >
        {name}
      </a>
    ) : (
      name
    )}
  </Text>
);

export default AttributionTag;

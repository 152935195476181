import * as React from "react";
import { IconProps } from "../types";

const EmojiOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9Z"
          fill={color}
        />
        <path
          d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
          fill={color}
        />
        <path
          d="M11.897 10.757C11.743 10.603 11.531 10.536 11.314 10.568C9.782 10.807 8.202 10.806 6.676 10.567C6.462 10.535 6.255 10.602 6.104 10.752C5.95 10.905 5.877 11.128 5.911 11.35C6.141 12.861 7.469 14.001 9 14.001C10.531 14.001 11.86 12.86 12.089 11.347C12.122 11.131 12.05 10.911 11.897 10.758V10.757Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default EmojiOutlineIcon;

import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PexelsVideo_StockVideosFragment = { __typename?: 'PexelsVideo', id: number, videographer: string, videographerUrl: string, videoFiles: Array<{ __typename?: 'PexelsVideoFile', id: number, quality?: Types.PexelsVideoQuality | null, link: string, fileType: string }>, videoPictures: Array<{ __typename?: 'PexelsVideoPicture', id: number, picture: string }> };

export type Search_StockVideosQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  pagination: Types.PaginationInput;
}>;


export type Search_StockVideosQuery = { __typename?: 'Query', SearchPexelsVideos?: Array<{ __typename?: 'PexelsVideo', id: number, videographer: string, videographerUrl: string, videoFiles: Array<{ __typename?: 'PexelsVideoFile', id: number, quality?: Types.PexelsVideoQuality | null, link: string, fileType: string }>, videoPictures: Array<{ __typename?: 'PexelsVideoPicture', id: number, picture: string }> }> | null };

export const PexelsVideo_StockVideosFragmentDoc = gql`
    fragment PexelsVideo_StockVideos on PexelsVideo {
  id
  videoFiles {
    id
    quality
    link
    fileType
  }
  videoPictures {
    id
    picture
  }
  videographer
  videographerUrl
}
    `;
export const Search_StockVideosDocument = gql`
    query Search_StockVideos($searchText: String!, $pagination: PaginationInput!) {
  SearchPexelsVideos(searchText: $searchText, pagination: $pagination) {
    ...PexelsVideo_StockVideos
  }
}
    ${PexelsVideo_StockVideosFragmentDoc}`;

/**
 * __useSearch_StockVideosQuery__
 *
 * To run a query within a React component, call `useSearch_StockVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_StockVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_StockVideosQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearch_StockVideosQuery(baseOptions: Apollo.QueryHookOptions<Search_StockVideosQuery, Search_StockVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_StockVideosQuery, Search_StockVideosQueryVariables>(Search_StockVideosDocument, options);
      }
export function useSearch_StockVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_StockVideosQuery, Search_StockVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_StockVideosQuery, Search_StockVideosQueryVariables>(Search_StockVideosDocument, options);
        }
export type Search_StockVideosQueryHookResult = ReturnType<typeof useSearch_StockVideosQuery>;
export type Search_StockVideosLazyQueryHookResult = ReturnType<typeof useSearch_StockVideosLazyQuery>;
export type Search_StockVideosQueryResult = Apollo.QueryResult<Search_StockVideosQuery, Search_StockVideosQueryVariables>;
import { useToast } from "@src/hooks/useToast";
import { Button } from "@src/ui/button";
import { Input } from "@src/ui/input";
import Text from "@src/ui/text";
import { FC, useState } from "react";
import isURL from "validator/lib/isURL";

type Props = {
  onAddLink: (link: string) => void;
};

const PasteLink: FC<Props> = (props) => {
  const [text, setText] = useState("");
  const { addErrorToast } = useToast();
  return (
    <>
      <Text type="P2" fontWeight="SemiBold" className="mb-1">
        Paste link
      </Text>
      <Input
        placeholder="Paste link here"
        className="w-full"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
      <Text type="P3" className="my-1 text-muted-foreground">
        Opus automatically embeds any YouTube or Vimeo links
      </Text>
      <Button
        onClick={() => {
          if (isURL(text, { protocols: ["https"] })) {
            props.onAddLink(text);
          } else {
            addErrorToast({
              message: "Invalid link. Please paste a valid link.",
              callsite: "add_media_paste_link",
            });
          }
        }}
      >
        Submit
      </Button>
    </>
  );
};

export default PasteLink;

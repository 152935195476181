import * as React from "react";
import { IconProps } from "../types";

const VolumeUpOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M5 5.75002H2.25C1.422 5.75002 0.75 6.42202 0.75 7.25002V10.75C0.75 11.578 1.422 12.25 2.25 12.25H5L10.48 15.758C10.813 15.971 11.25 15.732 11.25 15.337V2.66402C11.25 2.26902 10.813 2.03002 10.48 2.24302L5 5.75002Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.914 7.58594C14.695 8.36694 14.695 9.63294 13.914 10.4139"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.859 5.64111C17.714 7.49611 17.714 10.5041 15.859 12.3591"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default VolumeUpOutlineIcon;

import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnsplashImage_StockPhotosFragment = { __typename?: 'UnsplashImage', id: string, altDescription?: string | null, urls: { __typename?: 'UnsplashUrls', full: string }, user: { __typename?: 'UnsplashUser', id: string, name: string, links: { __typename?: 'UnsplashUserLinks', html: string } } };

export type PexelsPhoto_StockPhotosFragment = { __typename?: 'PexelsPhoto', id: number, photographer: string, photographerUrl: string, alt: string, src: { __typename?: 'PexelsSrc', original: string } };

export type Search_StockPhotosQueryVariables = Types.Exact<{
  searchText: Types.Scalars['String'];
  pagination: Types.PaginationInput;
}>;


export type Search_StockPhotosQuery = { __typename?: 'Query', SearchPexelsPhotos?: Array<{ __typename?: 'PexelsPhoto', id: number, photographer: string, photographerUrl: string, alt: string, src: { __typename?: 'PexelsSrc', original: string } }> | null, SearchUnsplashImages?: Array<{ __typename?: 'UnsplashImage', id: string, altDescription?: string | null, urls: { __typename?: 'UnsplashUrls', full: string }, user: { __typename?: 'UnsplashUser', id: string, name: string, links: { __typename?: 'UnsplashUserLinks', html: string } } }> | null };

export const UnsplashImage_StockPhotosFragmentDoc = gql`
    fragment UnsplashImage_StockPhotos on UnsplashImage {
  id
  urls {
    full
  }
  altDescription
  user {
    id
    name
    links {
      html
    }
  }
}
    `;
export const PexelsPhoto_StockPhotosFragmentDoc = gql`
    fragment PexelsPhoto_StockPhotos on PexelsPhoto {
  id
  src {
    original
  }
  photographer
  photographerUrl
  alt
}
    `;
export const Search_StockPhotosDocument = gql`
    query Search_StockPhotos($searchText: String!, $pagination: PaginationInput!) {
  SearchPexelsPhotos(searchText: $searchText, pagination: $pagination) {
    ...PexelsPhoto_StockPhotos
  }
  SearchUnsplashImages(searchText: $searchText, pagination: $pagination) {
    ...UnsplashImage_StockPhotos
  }
}
    ${PexelsPhoto_StockPhotosFragmentDoc}
${UnsplashImage_StockPhotosFragmentDoc}`;

/**
 * __useSearch_StockPhotosQuery__
 *
 * To run a query within a React component, call `useSearch_StockPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_StockPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_StockPhotosQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearch_StockPhotosQuery(baseOptions: Apollo.QueryHookOptions<Search_StockPhotosQuery, Search_StockPhotosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Search_StockPhotosQuery, Search_StockPhotosQueryVariables>(Search_StockPhotosDocument, options);
      }
export function useSearch_StockPhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Search_StockPhotosQuery, Search_StockPhotosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Search_StockPhotosQuery, Search_StockPhotosQueryVariables>(Search_StockPhotosDocument, options);
        }
export type Search_StockPhotosQueryHookResult = ReturnType<typeof useSearch_StockPhotosQuery>;
export type Search_StockPhotosLazyQueryHookResult = ReturnType<typeof useSearch_StockPhotosLazyQuery>;
export type Search_StockPhotosQueryResult = Apollo.QueryResult<Search_StockPhotosQuery, Search_StockPhotosQueryVariables>;
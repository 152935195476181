import * as React from "react";
import { IconProps } from "../types";

const GifIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M6.25 5.75H4.75C3.64543 5.75 2.75 6.64543 2.75 7.75V10.25C2.75 11.3546 3.64543 12.25 4.75 12.25H6.25V9.25H4.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 12.25V5.75"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.25 5.75H12.75C12.1977 5.75 11.75 6.19772 11.75 6.75V8.75M11.75 8.75V12.25M11.75 8.75H15.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default GifIcon;

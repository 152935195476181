import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import gql from "graphql-tag";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import { Input } from "@src/ui/input";
import {
  PexelsVideo_StockVideosFragment,
  useSearch_StockVideosQuery,
} from "./StockVideos.generated";
import { PexelsVideoQuality } from "@src/types.generated";
import Loading from "./Loading";
import Text from "@src/ui/text";
import useHover from "@src/hooks/useHover";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import AttributionTag from "@src/components/modals/addMedia/AttributionTag";
import { cn } from "@src/ui/lib/utils";

const PAGINATION_LIMIT = 50;

type Props = {
  onPexelsVideoSelect: (video: PexelsVideo_StockVideosFragment) => void;
};

const StockVideos: FC<Props> = (props) => {
  const [videosLoading, setVideosLoading] = useState<boolean[]>([]);
  const {
    value: searchText,
    setValue: setSearchText,
    debouncedValue: debouncedSearchText,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { data, loading } = useSearch_StockVideosQuery({
    variables: {
      searchText: debouncedSearchText || "restaurant kitchen",
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const stockVideos = data?.SearchPexelsVideos || [];
  const onVideoLoad = useCallback(
    (index: number) => {
      setVideosLoading((prev) => {
        const copy = [...prev];
        copy[index] = true;
        return copy;
      });
    },
    [setVideosLoading],
  );
  const allVideosLoaded = useMemo(
    () => videosLoading.every((l) => !!l) && !loading,
    [videosLoading, loading],
  );
  useEffect(() => {
    setVideosLoading(
      Array.from({
        length: data?.SearchPexelsVideos?.length || 0,
      }).map(() => false),
    );
  }, [data]);
  return (
    <>
      <Input
        placeholder="Search videos from Pexels"
        className="w-full"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className="mb-1 ml-auto">
        <Text type="P3">Stock Videos by </Text>
        <Text type="P3" fontWeight="SemiBold">
          <a
            className="underline"
            href="https://pexels.com"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </Text>
      </div>
      {!allVideosLoaded && <Loading key={`stock-videos-${searchText}`} />}
      <div className="columns-4 gap-2 overflow-y-auto">
        {allVideosLoaded && stockVideos.length === 0 ? (
          <Text type="P2" className="text-muted-foreground">
            No videos found
          </Text>
        ) : (
          data?.SearchPexelsVideos?.map((video, i) => (
            <Video
              hidden={!allVideosLoaded}
              video={video}
              onLoad={() => onVideoLoad(i)}
              key={`pexels-${video.id}`}
              onPexelsVideoSelect={props.onPexelsVideoSelect}
            />
          ))
        )}
      </div>
    </>
  );
};

const Video: FC<{
  onLoad: () => void;
  hidden: boolean;
  video: PexelsVideo_StockVideosFragment;
  onPexelsVideoSelect: (video: PexelsVideo_StockVideosFragment) => void;
}> = ({ video, hidden, onLoad, onPexelsVideoSelect }) => {
  const [isHovered, hoverBind] = useHover();
  const ref = useRef<HTMLImageElement>(null);
  const videoPicture = video.videoPictures[0].picture;
  let videoFile = video.videoFiles.find(
    (f) => f.quality === PexelsVideoQuality.Sd,
  );
  if (!videoFile) {
    videoFile = video.videoFiles[0];
  }
  useEffect(() => {
    if (ref.current?.complete && hidden) {
      onLoad();
    }
  }, [onLoad, ref, hidden]);
  return (
    <div
      className={cn(
        "relative mb-2 inline-block max-w-[110px] cursor-pointer align-top",
        hidden ? "hidden" : "",
      )}
      onClick={() => onPexelsVideoSelect(video)}
      {...hoverBind}
    >
      {isHovered && (
        <>
          <VideoPlayer
            videoProps={{
              loop: true,
              className: "absolute inset-0 max-w-[110px] rounded-md",
            }}
            hideControls
            playOnHover
            src={videoFile.link}
          />
          {video.videographer && (
            <AttributionTag
              name={video.videographer}
              url={video.videographerUrl}
            />
          )}
        </>
      )}
      <img
        ref={ref}
        alt=""
        onLoad={onLoad}
        onError={onLoad}
        src={videoPicture}
        className="max-w-[110px] rounded-md"
      />
    </div>
  );
};

export default StockVideos;

gql`
  fragment PexelsVideo_StockVideos on PexelsVideo {
    id
    videoFiles {
      id
      quality
      link
      fileType
    }
    videoPictures {
      id
      picture
    }
    videographer
    videographerUrl
  }
  query Search_StockVideos(
    $searchText: String!
    $pagination: PaginationInput!
  ) {
    SearchPexelsVideos(searchText: $searchText, pagination: $pagination) {
      ...PexelsVideo_StockVideos
    }
  }
`;

import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { FC } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  url: string;
  text: string;
};

const OpenLinkPill: FC<Props> = (props) => {
  return (
    <AutoLayout
      externalUrl={props.url}
      linkOpenInNewTab
      height={36}
      alignmentVertical={"center"}
      alignmentHorizontal={"center"}
      paddingHorizontal={12}
      spaceBetweenItems={4}
      style={{
        borderRadius: 12,
        background: deprecatedTones.gray6Alpha,
        backdropFilter: "blur(18px)",
        cursor: "pointer",
      }}
      hoverStyle={{
        background: deprecatedTones.gray7Alpha,
      }}
    >
      <Text
        type={"P3"}
        fontWeight={"SemiBold"}
        color={deprecatedTones.white}
        style={{ textTransform: "uppercase" }}
      >
        {props.text}
      </Text>
      <DeprecatedIcon
        type={"arrow-up-right"}
        color={deprecatedTones.white}
        iconSize={16}
        containerSize={16}
        strokeWidth={2}
      />
    </AutoLayout>
  );
};

export default OpenLinkPill;

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import gql from "graphql-tag";
import {
  PexelsPhoto_StockPhotosFragment,
  UnsplashImage_StockPhotosFragment,
  useSearch_StockPhotosQuery,
} from "./StockPhotos.generated";
import { useDebouncedInputValue } from "@src/hooks/useDebouncedInputValue";
import { Input } from "@src/ui/input";
import { compressPexelsImageURL, compressUnsplashImageURL } from "./utils";
import Loading from "./Loading";
import Text from "@src/ui/text";
import useHover from "@hooks/useHover";
import AttributionTag from "./AttributionTag";

const PAGINATION_LIMIT = 50;

type Props = {
  onPexelsPhotoSelect: (photo: PexelsPhoto_StockPhotosFragment) => void;
  onUnsplashImageSelect: (image: UnsplashImage_StockPhotosFragment) => void;
};

const StockPhotos: FC<Props> = (props) => {
  const {
    value: searchText,
    setValue: setSearchText,
    debouncedValue: debouncedSearchText,
  } = useDebouncedInputValue({ initialValue: "", delay: 300 });
  const { data, loading } = useSearch_StockPhotosQuery({
    variables: {
      searchText: debouncedSearchText || "restaurant kitchen",
      pagination: {
        limit: PAGINATION_LIMIT,
        offset: 0,
      },
    },
  });
  const [photosLoading, setPhotosLoading] = useState<boolean[]>([]);
  useEffect(() => {
    setPhotosLoading(
      Array.from({
        length:
          (data?.SearchPexelsPhotos?.length || 0) +
          (data?.SearchUnsplashImages?.length || 0),
      }).map(() => false),
    );
  }, [data]);

  const onPhotoLoad = useCallback(
    (index: number) => {
      setPhotosLoading((prev) => {
        const copy = [...prev];
        copy[index] = true;
        return copy;
      });
    },
    [setPhotosLoading],
  );

  const loaded = useMemo(
    () => photosLoading.every((l) => !!l) && !loading,
    [photosLoading, loading],
  );

  const photosLength =
    (data?.SearchPexelsPhotos?.length || 0) +
    (data?.SearchUnsplashImages?.length || 0);

  return (
    <>
      <Input
        placeholder="Search photos from Pexels and Unsplash"
        className="w-full"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className="mb-1 ml-auto">
        <Text type="P3">Stock Photos by </Text>
        <Text type="P3" fontWeight="SemiBold">
          <a
            className="underline"
            href="https://pexels.com"
            target="_blank"
            rel="noreferrer"
          >
            Pexels
          </a>
        </Text>
        <Text type="P3"> and </Text>
        <Text type="P3" fontWeight="SemiBold">
          <a
            className="underline"
            href="https://unplash"
            target="_blank"
            rel="noreferrer"
          >
            Unsplash
          </a>
        </Text>
      </div>
      {!loaded && <Loading key={`stock-photos-${searchText}`} />}
      <div className="columns-4 gap-2 overflow-y-auto">
        {photosLength === 0 && !loading && (
          <Text type="P2" className="text-muted-foreground">
            No photos found
          </Text>
        )}
        {photosLength > 0 && (
          <>
            {data?.SearchPexelsPhotos?.map((photo, i) => (
              <Photo
                key={`pexels-${searchText}-${i}`}
                onClick={() => props.onPexelsPhotoSelect(photo)}
                src={compressPexelsImageURL(photo.src.original, 110)}
                alt={photo.alt}
                hidden={!loaded}
                onLoad={() => onPhotoLoad(i)}
                photographer={photo.photographer}
                photographerUrl={photo.photographerUrl}
              />
            ))}
            {data?.SearchUnsplashImages?.map((image, i) => (
              <Photo
                key={`unsplash-${searchText}-${i}`}
                onClick={() => props.onUnsplashImageSelect(image)}
                src={compressUnsplashImageURL(image.urls.full, 110)}
                alt={image.altDescription || ""}
                hidden={!loaded}
                onLoad={() =>
                  onPhotoLoad(i + (data?.SearchPexelsPhotos?.length || 0))
                }
                photographer={image.user?.name}
                photographerUrl={image?.user?.links?.html}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

const Photo: FC<{
  onClick: () => void;
  alt: string;
  src: string;
  hidden: boolean;
  onLoad: () => void;
  photographer?: string;
  photographerUrl?: string;
}> = ({ onClick, alt, src, hidden, onLoad, photographer, photographerUrl }) => {
  const [isHovered, hoverBind] = useHover();
  const ref = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (ref.current?.complete && hidden) {
      onLoad();
    }
  }, [hidden, onLoad, ref]);
  return (
    <div
      className={`${hidden ? "hidden" : ""} relative mb-2 inline-block max-w-[110px] cursor-pointer align-top`}
      onClick={onClick}
      {...hoverBind}
    >
      <img
        className="max-w-[110px] rounded-md"
        src={src}
        alt={alt}
        onLoad={onLoad}
        onError={onLoad}
        ref={ref}
      />
      {isHovered && photographer && (
        <AttributionTag name={photographer} url={photographerUrl} />
      )}
    </div>
  );
};

export default StockPhotos;

gql`
  fragment UnsplashImage_StockPhotos on UnsplashImage {
    id
    urls {
      full
    }
    altDescription
    user {
      id
      name
      links {
        html
      }
    }
  }
  fragment PexelsPhoto_StockPhotos on PexelsPhoto {
    id
    src {
      original
    }
    photographer
    photographerUrl
    alt
  }
  query Search_StockPhotos(
    $searchText: String!
    $pagination: PaginationInput!
  ) {
    SearchPexelsPhotos(searchText: $searchText, pagination: $pagination) {
      ...PexelsPhoto_StockPhotos
    }
    SearchUnsplashImages(searchText: $searchText, pagination: $pagination) {
      ...UnsplashImage_StockPhotos
    }
  }
`;

import * as React from "react";
import { IconProps } from "../types";

const ChevronsOpenIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="12"
        height="12"
        fill="none"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6 11.75C5.808 11.75 5.616 11.677 5.47 11.53L2.72 8.78002C2.427 8.48702 2.427 8.01202 2.72 7.71902C3.013 7.42602 3.488 7.42602 3.781 7.71902L6.001 9.93902L8.221 7.71902C8.514 7.42602 8.989 7.42602 9.282 7.71902C9.575 8.01202 9.575 8.48702 9.282 8.78002L6.532 11.53C6.386 11.676 6.194 11.75 6.002 11.75H6Z"
          fill={color}
        />
        <path
          d="M8.75 4.49999C8.558 4.49999 8.366 4.42699 8.22 4.27999L6 2.05999L3.78 4.27999C3.487 4.57299 3.012 4.57299 2.719 4.27999C2.426 3.98699 2.426 3.51199 2.719 3.21899L5.47 0.469994C5.763 0.176994 6.238 0.176994 6.531 0.469994L9.281 3.21999C9.574 3.51299 9.574 3.98799 9.281 4.28099C9.135 4.42699 8.943 4.50099 8.751 4.50099L8.75 4.49999Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default ChevronsOpenIcon;

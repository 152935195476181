import * as React from "react";
import { IconProps } from "../types";

const LinkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M12.188 16.484C11.091 16.484 9.99598 16.067 9.16198 15.232L6.98698 13.057C5.31598 11.386 5.31598 8.667 6.98698 6.996C7.34298 6.64 7.73998 6.359 8.17698 6.15C8.54798 5.97 8.99698 6.129 9.17698 6.504C9.35598 6.878 9.19798 7.325 8.82298 7.504C8.53998 7.639 8.28198 7.822 8.05698 8.047C6.96098 9.143 6.96098 10.91 8.04698 11.997L10.222 14.172C11.308 15.257 13.075 15.258 14.161 14.172C15.257 13.076 15.257 11.309 14.171 10.223L13.24 9.292C12.947 8.999 12.947 8.524 13.24 8.231C13.533 7.938 14.008 7.938 14.301 8.231L15.232 9.162C16.903 10.833 16.903 13.551 15.232 15.222C14.39 16.064 13.288 16.484 12.188 16.484Z"
          fill={color}
        />
        <path
          d="M9.501 11.923C9.221 11.923 8.953 11.766 8.824 11.496C8.645 11.122 8.803 10.675 9.178 10.496C9.461 10.361 9.719 10.178 9.944 9.953C11.04 8.857 11.04 7.09 9.954 6.003L7.779 3.828C6.694 2.743 4.926 2.742 3.84 3.828C2.744 4.924 2.744 6.691 3.83 7.777L4.761 8.708C5.054 9.001 5.054 9.476 4.761 9.769C4.468 10.062 3.993 10.062 3.7 9.769L2.769 8.838C1.098 7.167 1.098 4.449 2.769 2.778C4.451 1.097 7.169 1.096 8.839 2.768L11.014 4.943C12.685 6.614 12.685 9.333 11.014 11.004C10.658 11.36 10.261 11.641 9.824 11.85C9.72 11.9 9.61 11.923 9.501 11.923Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default LinkIcon;

import React from "react";

import { CoverImageFragment } from "@src/fragments.generated";
import { BackgroundGradient, CoverImageInput } from "@src/types.generated";
import CoverImage from "@src/components/ui/CoverImage";
import Text from "@ui/text";
import CoverImagePickerEmojiTab from "@src/components/ui/CoverImagePickerEmojiTab";

type Props = {
  coverImage?: CoverImageFragment;
  onCoverImageSelected?: (coverImage: Omit<CoverImageInput, "id">) => void;
};

const Emojis: React.FC<Props> = (props) => {
  const coverImagePreview =
    props.coverImage?.emojiIcon || props.coverImage?.background
      ? props.coverImage
      : undefined;
  const updateEmoji = React.useCallback(
    (emojiIcon: string) => {
      if (!props.onCoverImageSelected) return;
      props.onCoverImageSelected({
        emojiIcon,
        background: props.coverImage?.background,
      });
    },
    [props],
  );
  const updateBackground = React.useCallback(
    (background: BackgroundGradient) => {
      if (!props.onCoverImageSelected) return;
      props.onCoverImageSelected({
        emojiIcon: props.coverImage?.emojiIcon || null,
        background,
      });
    },
    [props],
  );
  const clearEmoji = React.useCallback(() => {
    if (!props.onCoverImageSelected) return;
    props.onCoverImageSelected({
      emojiIcon: null,
      background: props.coverImage?.background,
    });
  }, [props]);
  return (
    <div className="flex flex-1 flex-col gap-4 self-stretch overflow-hidden">
      <div className="flex flex-col items-center justify-center gap-2 rounded-xl bg-gray-1 p-4">
        <CoverImage
          coverImage={coverImagePreview}
          editEnabled={false}
          style={{
            width: 320,
            height: 160,
          }}
        />
        <Text
          type="P3"
          fontWeight="SemiBold"
          className="text-center text-muted-foreground"
        >
          Preview
        </Text>
      </div>
      <div className="flex flex-1 self-stretch">
        <CoverImagePickerEmojiTab
          selectedGradient={props.coverImage?.background}
          emoji={props.coverImage?.emojiIcon || ""}
          setSelectedGradient={updateBackground}
          coverImage={props.coverImage}
          clearEmoji={clearEmoji}
          setEmoji={updateEmoji}
        />
      </div>
    </div>
  );
};

export default Emojis;
